import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Alert, AlertList } from './alert.model';
import { LocalStorageService } from '../localstorage/local-storage.service';
import { Pagination } from 'app/core/api/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    private _alerts: BehaviorSubject<Alert[] | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";
    pagination: Pagination;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private _localStorage: LocalStorageService) {
    }

    /**
     * Getter for categories
     */
    get alerts$(): Observable<Alert[]> {
        return this._alerts.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get event list 
     */
    getAlertsInitial(): Observable<AlertList> {
        return this.getAlerts(null, null, null, null, null)
    }
    getAlerts(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string): Observable<AlertList> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        return this._httpClient.get<AlertList>(`${BASE_URL}alert/`, {
            params: {
                page: page ? ++page : 1,
                ordering: sort ? sort : '',
                search: query ? query : '',
                page_size: batch_size ? batch_size : 10
            }
        }).pipe(
            tap((response) => {
                this.pagination = {
                    page: --response.page,
                    total_count: response.total_count
                };
                if (response.data.length == 0)
                    this.errorMessage = "There are no alerts to display!"
                this._alerts.next(response.data);
            })
        );
    }

    deleteAlert(eventId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}alert/${eventId}/`).pipe(
            tap((res: any) => {
                this.pagination.total_count -= 1
                this._alerts.next(this._alerts.value.filter(event => event.id !== eventId));
            }, err => of([]))
        )
    }

    createAlert(credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}alert/`, credentials);
    }

    updateAlert(credentials, eventId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}alert/${eventId}/`, credentials)
    }



}
